
:root {
    --main-bg-color: #000000 ;
    --secondary-bg-color: #000000;
    --main-text-color: #ffffffd5;
    --main-text-color-shadow: rgba(201, 169, 169, 0.446)    ;
    --secondary-text-color:  rgba(255, 255, 255, 0.25);
    --font-family: 'Bodoni Moda', serif;
}
.all-bg{
    background-color: var(--main-bg-color) ;
    background-size: cover;
    background-repeat:repeat;
    color: var(--main-text-color);
    font-family: var(--font-family);
    font-weight: 600;
    height: auto;
    width: auto;
    margin: 0;
    padding: 0;

}

/* Navbar */
.navbar{
    background-color: var(--secondary-bg-color);
    color: var(--main-text-color) ;
    width: 100%;
    font-family: var(--font-family);
    font-weight: 500;
}
.logo {
    height: 50px; 
    width: 60px;
    background-color: var(--secondary-bg-color);
    margin: -3px 1px 1px 5px;
    display: flex;
    align-items: center;
    justify-content:center;
    position: relative;
}
.icons{
    display: flex;
    align-items: center;
    justify-content:space-between;
    background-color:#060606;
    margin: -8px 30px 0px 0px;
    padding: 20px 15px 14px 15px;
    height: 50px;
    width: 200px;
    /* border: 2px solid var(--main-text-color); */
}
.icon{
    height: 50px;
}
@media (max-width: 991px) {
    .navbar-collapse{
        display: flex;
        flex-direction: row;
    }
}
@media (max-width: 777px) {
    .navbar-collapse{
        display: flex;
        flex-direction: column;
    }
}

/* Home page */
.home-main-div{
    margin: 0;
    padding:0;
    font-family: var(--font-family);
    width: 100%;
    height:100vh;
}
.background-top{
    width: 100%;
    height:100%;
    overflow: hidden;
    position: relative;
    background-image: url(Background.png);
    background-repeat: no-repeat;
    background-size: cover;
    animation: slide 10s infinite;
}
.content{
    position: absolute;
    top:30%;
    left:50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    text-align: center;
}
.content-header{
    margin-bottom: 40px;
}
.content h1 {
    color: whitesmoke;
    font-size: 60px;
    letter-spacing: 10px;
}
.content a{
    background-color: #ffffff39;
    padding: 10px 25px ;
    text-decoration: none;
    font-size: 15px;
    border-radius: 20px;
}
.home-main-div p{
    font-size: 15px;
    padding: 10px;
    line-height: 25px;
}

@keyframes slide {
    0%{
        background-image: url(Background.png);
    }
    33%{
        background-image: url(Background-blue.png);
    }
    67%{
        background-image: url(Background-pink.png);
    }
}
.info-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    background-color: #e7e7e7;
}

.profilepic{
    height: 175px;
    width:170px;
    margin: 10px;
    border-radius: 15px 50px;
}
  
.single {
    max-width: 650px;
    margin: 0 100px;
    text-align: center;
    position: relative;
    z-index: 2; 
}

.home-newsletter .single h2 {
    font-size: 22px;
    color: #5f5c5f95;
    text-transform: uppercase;
    margin-bottom: 40px; 
}
.form-control {
    height: 50px;
    background: rgba(255, 255, 255, 0.6);
    border-color: transparent;
    border-radius: 20px 0 0 20px; 
}
.home-newsletter .single .form-control:focus {
    box-shadow: none;
    border-color:#5f5c5f95; 
}
.home-newsletter .single .btn {
    min-height: 50px; 
    border-radius: 0 20px 20px 0;
    background: #5f5c5f95;
    color: #e7e7e7;
}
@media (max-width: 575px) {
    .info-container{
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
    }
    .content h1{
        font-size: 40px;
    }
}

/* About page */
.about-flex-container {
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.profile-pic-about-container{
    display: flex;
    align-items:center;
    justify-content: center;
    margin-right: 50px;
}

.profile-pic-about{
    height: 400px ;
    width: 370px;
    border-radius: 10px;
    border: 5px solid var(--secondary-text-color);
}
.about-info{
    height: auto ;
    width: 60%;
    margin: 0px 50px 0px 100px ;
    text-align: left; 
}
.about-info h1{
    height: auto;
    width: auto;
    text-align: left;
    font-size: 50px;
    align-items: center;
}
.about-info h4{
    height: auto ;
    width: auto;
    text-align: left;
    margin-bottom: 25px;
    color: rgba(234, 114, 142, 0.531);
    font-weight: 600;
}
.about-info p{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 50vw;
    font-size: 17px;
    margin-bottom: 30px;
}
.about-skills{
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}
ul li {
    list-style-type: circle;
    font-size: 17px;
}
@media (max-width: 991px) {
    .about-flex-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .about-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: auto;
        font-size: 17px;
        margin: 25px;
    }
    .about-info p{
        width: 80vw;
    }
    .profile-pic-about-container{
        display: flex;
        align-items:center;
        justify-content: center;
        margin: 10px;
    }
}
@media (max-width: 400px) {
    .about-info p{
        width: 95vw;
        padding: 10px;
    }
}

/* Skills page */
.skills-flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}
.skills-detail{
    text-align: center;
    margin: 30px;
    height: auto;
    width: 60%;
}
.skills-detail h1{
    text-align: center;
    margin: 15px;
    font-weight: 600;
}
.skills-header h2{
    font-size: 35px;
    font-weight: 700;
    color:  rgba(89, 89, 89, 0.6);
    margin-top: 3%;
}
.carousel{
    height: 35vh;
    width: 55vw;
    margin: 10px;
    background-color: rgba(89, 88, 88, 0.6);
    border-radius: 20px;
}
.carousel-inner h1{
    margin: 10px;
}
.carousel-inner{
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
}
.carousel-inner .left{
    flex: 10%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: grid;
    place-items: center;
    cursor: pointer;
}
.carousel-inner .center{
    flex: 90%;
    height: 100%;
    display: grid;
}
.carousel-inner .right{
    flex: 10%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: grid;
    place-items: center;
    cursor: pointer;
}

@media (max-width: 991px) {
    .carousel{
        width: 80vw;
        margin: 25px;
    }
    .skills-detail{
        text-align: center;
        margin: 30px;
        height: auto;
        width: 90%;
    }
}


/* Projects page */
.project-flex-container{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.header h1{
    font-family: var(--font-family);
    color: var(--secondary-text-color);
    font-size: 100px;
    font-weight: 600;
    padding: 50px;
}
.project-container{
    margin: 50px;
}
.project{
    padding: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: auto;
    border: 5px solid rgba(255, 255, 255, 0.15);
}
.project-title{
    font-size: 50px;
    font-weight: 600;
}
.github-link{
    cursor: pointer;
    text-decoration: none;
}
.project-discription{

    padding: 2px;
    font-size: 20px;
    text-align: left;
    margin: 3%;
}
.in-progress{
    color: rgba(234, 114, 142, 0.4);
}
.built-with{
    font-size: 15px;  
    text-align: left;
    margin-top: 10%;
    color:rgba(234, 114, 142, 0.6);
    padding: 2px;
    text-align: left;
    margin: 3%;
}
@media (max-width: 991px) {
    .header h1{
        font-size: 80px;
        padding: 10px;
    }
    .project-container{
        margin: 10px;
    }
    .project{
        width: 100%;
    }
}

/* Experience page */
.experience-flex-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px;
}
.experience-title{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    width: 25%;
    height: 90vh
}
.experience-title button{
    background-color:#35353571;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    height: 50px;
    width: 20vw;
    margin: 10px ;
    color: rgba(234, 114, 142, 0.531);
}
.experience-details{
    display: flex;
    flex-direction: column;
    align-items:center;
    border: 2px solid #e7e7e7;
    width: 100%;
    height: auto;
    padding: 5%;
}
.experience-card{
    padding:10px;
    align-items: center;
}
.experience-card h1{
    align-items:center;
    justify-content: center;
    font-weight: 600;

}
.experience-card h4{
    color: rgba(234, 114, 142, 0.305);
    font-weight: 600;

}
.experience-card p{
    margin:50px 20px 50px 20px

}
.expereince-skills{
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    margin-top: 15px;
}
.expereince-skills li {
    list-style-type: circle;
    font-size: 17px;
    text-align: left;
    /* margin-left: 50px; */
}
@media (max-width: 991px) {
    .experience-flex-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .experience-title{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: auto;
        height: auto;
        margin-bottom: 10px;
    }
    .experience-title button{
        width: 40%; 
    }
    .experience-card p{
        margin:50px 0px 50px 0px
    
    }
}

/* Extras */
.button4 {
    background-color: #e7e7e7; color: black;
} 
/* Gray */
a{
    font-size: 20px;
}
  
/* visited link */
a:visited {
    color:var(--main-text-color);
}
  
/* mouse over link */
a:hover {
    color:var(--main-text-color);
}
a:active {
    text-decoration: underline;
}

/* contact me  */
.contact-container{
    display: flex;
    flex-direction: column;
    padding: 50px 250px;
    overflow: hidden;
}
@media (max-width: 991px) {
    .contact-container {
        width: 100%;
        height: 100vh;
        padding: 20px;
      }
      
      form {
        max-width: 600px;
        margin: 0 auto;
      }
      
      .form-group {
        margin-bottom: 20px;
      }
      
      .form-row {
        display: flex;
        flex-wrap: wrap;
      }
      
      .col {
        flex: 1;
        margin-right: 10px;
      }
      
      input,
      textarea {
        width: 100%;
        padding: 10px;
      }
      
      .btn {
        width: 100%;
      }
}

/* Error Page */
.error-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
}
.error-page h2{
    font-size: 75px;
}
.error-page p{
    font-size: 20px;
}
.error-btn{
    width: 100px;
    color: #000000;
}

